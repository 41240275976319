import { PriceUpdate } from '../../service/types/price-update';
import { PriceDataPoint } from '../../service/types/price-datapoint';
import { ChangeHistory } from '../../service/types/change-history';
import { GTINDataPoint } from '../../service/types/gtin-datapoint';
import formatDate from './utils/format-date';

// const API_BASE_URL = 'https://d14s2axy49j51f.cloudfront.net';
const API_BASE_URL = 'https://sandbox.boticapp.com.ar';
// const API_BASE_URL = 'https://boticapp.com.ar';

// const API_BASE_URL = '';
const API_KEY = encodeURIComponent('uK_$LbzG!7tJ?=m');

const buildUrl = (endpoint: string) => `${API_BASE_URL}/api/${endpoint}?api_key=${API_KEY}`;

export const fetchProducts = async (): Promise<PriceUpdate[]> => {
  const url = buildUrl('products');

  return fetch(url).then(res => res.json());
};

export const getPriceUpdates = async (reg_code: number): Promise<PriceDataPoint[]> => {
  const key = `updates/${reg_code}`;
  const url = buildUrl(key);

  const response = await fetch(url);

  return response.json();
};

export const getHistory = async (): Promise<ChangeHistory[]> => {
  const key = `history`;
  const url = buildUrl(key);

  const response = await fetch(url);
  const history = await response.json();

  return history
    .sort((a: ChangeHistory, b: ChangeHistory) => {
      return b.date.localeCompare(a.date);
    })
    .map((i: ChangeHistory) => {
      return {
        ...i,
        date: formatDate(i.date),
      };
    });
};

export const getGTINProduct = async (reg_code: number): Promise<GTINDataPoint[]> => {
  const key = `gtin/${reg_code}`;
  const url = buildUrl(key);

  const response = await fetch(url);

  return response.json();
};