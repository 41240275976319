import React from 'react';

import { AppBar, Toolbar, Typography, withStyles } from '@material-ui/core';
import { StyledProps } from '../../types/styled-props';
import { mainStyles } from '../../styles';
import { LocalPharmacy, AccountCircle } from '@material-ui/icons';

class AppHeader extends React.Component<StyledProps> {
  private isLoggedIn() {
    return sessionStorage.getItem('session') === 'dsd231';
  }

  public render() {
    return (
      <AppBar position="sticky">
        <Toolbar variant="dense" className={this.props.classes.toolbar}>
          <Typography variant="h6">
            <LocalPharmacy className={this.props.classes.appLogo}></LocalPharmacy> BoticAPP
          </Typography>
          {this.isLoggedIn() ? (
            <Typography variant="caption" className={this.props.classes.user}>
              ASyA FARMACÉUTICA SRL
              <AccountCircle className={this.props.classes.userLogo}></AccountCircle>
            </Typography>
          ) : null}
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(mainStyles)(AppHeader);
